export default class DrivingTestsRdvPermis {

    constructor() {

        this.bindEvents();

    }

    bindEvents() {
        $(".open-rdvpermis-comment-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("action_url");

            $("#rdv_permis_comment_form").attr("action", actionURL);
            $("#rdv_permis_comment").val($(this).data("content"));
            $("#rdv_permis_comment_modal").modal();
        });

        $(".open-rdvpermis-update-optimal-week-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("action_url");

            $("#rdv_permis_update_optimal_week_form").attr("action", actionURL);
            $("#rdv_permis_optimal_week").val($(this).data("optimal_test_week_date"));
            $("#update_optimal_week_modal").modal();
        });

        $(".open-rdvpermis-reserved-date-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("action_url");

            $("#rdv_permis_reserved_date_form").attr("action", actionURL);
            $("#rdv_permis_reserved_date").attr("min", $(this).data("min_date"))
            $("#reserved_date_modal").modal();
        }); 

        $(".open-driving-test-prebook-date-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("action_url");

            $("#update_driving_test_prebook_date_form").attr("action", actionURL);
            $("#session_driving_test_prebooked_at").val($(this).data("prebook_date"));
            $("#update_driving_test_prebook_date_modal").modal();
        });
    }
}