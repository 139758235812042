export default class ClientsPsh {

    constructor() {

        this.bindEvents();

    }

    bindEvents() {
        $(".open-customer-disability-comment-modal").off("click").on("click", function(e) {
            e.preventDefault();

            $("#customer_disability_comment").val($(this).data("content"));
            $("#customer_disability_comment_modal").modal();
        });

        $(".open-admin-disability-comment-modal").off("click").on("click", function(e) {
            e.preventDefault();

            var actionURL = $(this).data("action_url");

            $("#admin_disability_comment_form").attr("action", actionURL);
            $("#admin_disability_comment").val($(this).data("content"));
            $("#admin_disability_comment_modal").modal();
        });

    }
}